import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UserRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Obtiene el userType del sessionStorage
    const storedUserType = JSON.parse(sessionStorage.getItem("userType")); // Asegúrate de parsear el valor

    console.log("Stored User Type:", storedUserType); // Imprime el userType almacenado

    // Redireccionar basado en el tipo de usuario
    if (storedUserType) {
      switch (storedUserType) {
        case "Admin":
          navigate("/Admin_user"); // Asegúrate de que la ruta sea correcta
          break;
        case "Normal":
          navigate("/Nrml_user"); // Asegúrate de que la ruta sea correcta
          break;
        default:
          navigate("/Login"); // Asegúrate de que la ruta sea correcta
      }
    } else {
      navigate("/Login"); // Redirige a login si no hay userType
    }
  }, [navigate]); // Añade 'navigate' a la lista de dependencias

  return (
    <div>
      <p>Validando permisos y redirigiendo...</p>
    </div>
  );
};

export default UserRedirect;