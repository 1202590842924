import { create } from 'zustand';

const storedUserType = JSON.parse(sessionStorage.getItem("userType")); // Asegúrate de parsear el valor
const storedUser = JSON.parse(sessionStorage.getItem("user"));


export const useStore = create((set) => ({
  users: [],
  products: [],
  customers: [],
  orders: [],
  currentUser:  storedUser,
  userType: storedUserType || null,
  setUsers: (users) => set({ storedUser }),
  setProducts: (products) => set({ products }),
  setCustomers: (customers) => set({ customers }),
  setOrders: (orders) => set({ orders }),
  setCurrentUser:  (currentUser ) => set({ currentUser  }),
  setUserType: (userType) => set({ userType }),
}));