import React from 'react'
import './notfound.css'



function NotFound() {
  return (
    <div className="container mt-5 ">
      <div className="card bg-transparent border-light">
        <div className="card-body">
          <div className=' text-white'>Error 404 - Not Found</div>
        </div>
      </div>
    </div>
  )
}

export default NotFound