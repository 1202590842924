import React, { useState, useEffect } from 'react';

import { 
  Modal, 
  Button, 
  Form, 
  Input, 
  Select, 
  Table, 
  message, 
  Upload, 
  Switch 
} from 'antd';
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  UploadOutlined 
} from '@ant-design/icons';
import './productManagement.css';

const { Option } = Select;

const ProductManagement = () => {
  // State management
  const [products, setProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [form] = Form.useForm();

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, []);

  // Fetch products from API
  const fetchProducts = async () => {
    try {
      // Replace with actual API call
      const mockProducts = [
        {
          id: 1,
          name: 'Classic T-Shirt',
          category: 'Clothing',
          price: 29.99,
          stock: 100,
          isActive: true
        },
        {
          id: 2,
          name: 'Vintage Jacket',
          category: 'Clothing',
          price: 129.99,
          stock: 50,
          isActive: true
        }
      ];
      setProducts(mockProducts);
    } catch (error) {
      message.error('Failed to fetch products');
    }
  };

  // Open modal for adding/editing product
  const handleAddEdit = (product = null) => {
    setCurrentProduct(product);
    setIsModalVisible(true);
    
    // Reset or populate form
    form.resetFields();
    if (product) {
      form.setFieldsValue(product);
    }
  };

  // Save product (add/edit)
  const handleSave = async (values) => {
    try {
      // Replace with actual API call
      if (currentProduct) {
        // Edit existing product
        const updatedProducts = products.map(p => 
          p.id === currentProduct.id ? { ...p, ...values } : p
        );
        setProducts(updatedProducts);
        message.success('Product updated successfully');
      } else {
        // Add new product
        const newProduct = {
          ...values,
          id: products.length + 1,
          isActive: true
        };
        setProducts([...products, newProduct]);
        message.success('Product added successfully');
      }
      
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to save product');
    }
  };

  // Delete product
  const handleDelete = (productId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this product?',
      onOk: () => {
        const updatedProducts = products.filter(p => p.id !== productId);
        setProducts(updatedProducts);
        message.success('Product deleted successfully');
      }
    });
  };

  // Toggle product active status
  const handleToggleStatus = (product) => {
    const updatedProducts = products.map(p => 
      p.id === product.id 
        ? { ...p, isActive: !p.isActive } 
        : p
    );
    setProducts(updatedProducts);
  };

  // Table columns configuration
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${price.toFixed(2)}`
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: 'Status',
      key: 'isActive',
      render: (record) => (
        <Switch 
          checked={record.isActive}
          onChange={() => handleToggleStatus(record)}
        />
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <div className="action-buttons">
          <Button 
            icon={<EditOutlined />} 
            onClick={() => handleAddEdit(record)}
            type="primary"
            ghost
          >
            Edit
          </Button>
          <Button 
            icon={<DeleteOutlined />} 
            onClick={() => handleDelete(record.id)}
            type="danger"
            ghost
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="product-management-container">
      <div className="product-management-header">
        <h1>Product Management</h1>
        <Button 
          type="primary" 
          icon={<PlusOutlined />}
          onClick={() => handleAddEdit()}
        >
          Add New Product
        </Button>
      </div>

      <Table 
        columns={columns} 
        dataSource={products}
        rowKey="id"
        pagination={{ 
          pageSize: 10, 
          showSizeChanger: true 
        }}
      />

      <Modal
        title={currentProduct ? 'Edit Product' : 'Add New Product'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
        >
          <Form.Item
            name="name"
            label="Product Name"
            rules={[{ required: true, message: 'Please input product name' }]}
          >
            <Input placeholder="Enter product name" />
          </Form.Item>

          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: 'Please select category' }]}
          >
            <Select placeholder="Select category">
              <Option value="Clothing">Clothing</Option>
              <Option value="Accessories">Accessories</Option>
              <Option value="Footwear">Footwear</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: 'Please input price' }]}
          >
            <Input 
              type="number" 
              prefix="$" 
              placeholder="Enter price" 
            />
          </Form.Item>

          <Form.Item
            name="stock"
            label="Stock Quantity"
            rules={[{ required: true, message: 'Please input stock quantity' }]}
          >
            <Input type="number" placeholder="Enter stock quantity" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentProduct ? 'Update Product' : 'Add Product'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductManagement;