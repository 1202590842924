import React from 'react';
import Nav from '../../navBar/navBar.jsx';
import Header from '../../dashboard/Header/Header.jsx';
import UserManagement from '../../userManagement/userManagement.jsx';
import SalesReports from '../../reeport/salesReport.jsx';
import ProductManagement from '../../productManagement/productManagement.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import './admin_user.css';

function admin_user() {
  return ( 
    <div className='AdminDiv'>
    <div className='grid-container'>
      <div className='NavBar'>
        <Nav />
      </div>
    <div className= "Tittle"> <p>Hola mundo Administrador</p></div>
         


    </div>
  </div>
);
}

export default admin_user