import React, { useState, useEffect } from 'react';
import './salesReport.css';
import * as XLSX from 'xlsx'; // Importar la biblioteca xlsx
import NavBar from '../navBar/navBar';


function SalesReport() {
  const [salesData, setSalesData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const mockSalesData = [
          { id: 1, date: '2023-06-01', product: 'Product A', quantity: 10, revenue: 500 },
          { id: 2, date: '2023-06-02', product: 'Product B', quantity: 5, revenue: 250 },
        ];

        setSalesData(mockSalesData);
        setTotalSales(mockSalesData.reduce((sum, item) => sum + item.revenue, 0));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sales data:', error);
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const renderSalesTable = () => {
    return (
      

      <table className="sales-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Precio</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((sale) => (
            <tr key={sale.id}>
              <td>{sale.date}</td>
              <td>{sale.product}</td>
              <td>{sale.quantity}</td>
              <td>${sale.revenue.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSalesSummary = () => {
    return (
      <div className="sales-summary">
        <h2>Informe de Ventas</h2>
        <div className="summary-metrics">
          <div className="metric">
            <span>Total Ventas</span>
            <strong>${totalSales.toFixed(2)}</strong>
          </div>
          <div className="metric">
            <span>Total Transaccions</span>
            <strong>{salesData.length}</strong>
          </div>
        </div>
      </div>
    );
  };

  const downloadExcel = () => {
    // Crear un libro de trabajo y una hoja de trabajo
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(salesData);

    // Agregar la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'SalesData');

    // Descargar el archivo Excel
    XLSX.writeFile(wb, 'Sales_Report.xlsx');
  };

  if (loading) {
    return <div>Loading sales data...</div>;
  }

  return (
    <div className='ResponsiveShiet'>
      <div className='Body_Content'>
      <div className='NavBar'>
          <NavBar />
        </div>
    <div className="sales-report-container">
      <h1>Selling Report</h1>
      {renderSalesSummary()}
      <button onClick={downloadExcel} className="btn btn-primary">
        Download Excel
      </button>
      {renderSalesTable()}
      </div>
    </div>
    </div>
  );
}

export default SalesReport;