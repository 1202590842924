import React, { useState } from 'react';
import './settings.css';
import Nav from '../navBar/navBar.jsx';
import { Button, Modal, Form } from 'react-bootstrap';

const Settings = () => {
  const [users, setUsers] = useState([
    { id: 1, username: 'admin', email: 'admin@example.com' },
    { id: 2, username: 'user1', email: 'user1@example.com' },
  ]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newUser , setNewUser ] = useState({ username: '', email: '' });

  const handleAddUser  = (e) => {
    e.preventDefault();
    const newUserData = { id: users.length + 1, ...newUser  };
    setUsers([...users, newUserData]);
    setNewUser ({ username: '', email: '' });
    setShowAddModal(false);
  };

  const handleDeleteUser  = (id) => {
    setUsers(users.filter(user => user.id !== id));
  };

  return (
    <div className='ResponsiveShiet'>
      <div className='Body_Content'>
      <div className="NavBar">
        <Nav />
      </div>
      <div className="settings-container ">
        <h2>Manage Something</h2>
        <Button variant="primary" onClick={() => setShowAddModal(true)}>
          Add User
        </Button>
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>
                  <Button variant="danger" onClick={() => handleDeleteUser (user.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal for adding a new user */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddUser }>
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={newUser .username}
                  onChange={(e) => setNewUser ({ ...newUser , username: e.target.value })}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={newUser .email}
                  onChange={(e) => setNewUser ({ ...newUser , email: e.target.value })}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Add User
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      </div>
    </div>
  );
};

export default Settings;