import React, { useState } from 'react';
import './config_usr.css';
import Nav from '../navBar/navBar.jsx';
import { Button, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


const ConfigUsr = () => {
  const [userInfo, setUserInfo] = useState({
    nick:'Usuario',
    firstName: 'Nombre -Ejemplo',
    lastName: 'Apellido -Ejemplo',
    email: 'usuario@example.com',
    phoneNumber: '+569 777 777 77',
    business: 'Business',
    enterprise: 'Enterprise',
    pwd: '123456',
    profileImage: null,
  });

  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
//handle para el nick
  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

//handle para el firstName
const handlefirstNameChange = (e) => {
  const { name, value } = e.target;
  setUserInfo({ ...userInfo, [name]: value });
};

//handle para el lastName
const handlelastNameChange = (e) => {
  const { name, value } = e.target;
  setUserInfo({ ...userInfo, [name]: value });
};

//handle para el email  
const handleEmailChange = (e) => {
  const { name, value } = e.target;
  setUserInfo({ ...userInfo, [name]: value });
};

//handle para el phoneNumber
const handlePhoneNumberChange = (e) => {
  const { name, value } = e.target;
  setUserInfo({ ...userInfo, [name]: value });
};
  //handle para el business
  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  //handle para el enterprise
  const handleEnterpriseChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  
//handle para el password
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo({ ...passwordInfo, [name]: value });
  };
//handle para el Image Change
  const handleImageChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} cargado con éxito`);
      setUserInfo({ ...userInfo, profileImage: info.file.originFileObj });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} no se pudo cargar`);
    }
  };

  //handle para el submit
  const handleUserSubmit = (e) => {
    e.preventDefault();
    console.log('Información del usuario actualizada:', userInfo);
    message.success('Información del usuario actualizada con éxito');
  };

//handle para el password submit
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInfo.newPassword !== passwordInfo.confirmPassword) {
      message.error('Las contraseñas no coinciden');
      return;
    }
    console.log('Contraseña cambiada:', passwordInfo);
    message.success('Contraseña cambiada con éxito');
    setPasswordInfo({ currentPassword: '', newPassword: '', confirmPassword: '' });
  };

  //nick, firstName, lastName, email, phoneNumber, bussines, enterprise, pwd
  return (
    <div className='Body_user_Config'>
      <div className='Tittle_user_Config'>
        <h2>Configuración de Usuario</h2>
      </div>
      <div className='NavBar'>
        <Nav />
      </div>
      <div className='config-container'>
        <Form onSubmit={handleUserSubmit} layout="vertical">
          <Form.Item label="Nick de Usuario">
            <Input
              name="Nick"
              value={userInfo.nick}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="First Name">
            <Input
              name="firstName"
              value={userInfo.firstName}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Last Name">
            <Input
              name="lastName"
              value={userInfo.lastName}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              name="email"
              type="email"
              value={userInfo.email}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Phone Number">
            <Input
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Business">
            <Input
              name="business"
              value={userInfo.business}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Enterprise">
            <Input
              name="enterprise"
              value={userInfo.enterprise}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Imagen de Perfil">
            <Upload
              name="profileImage"
              showUploadList={false}
              onChange={handleImageChange}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Cargar Imagen</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Guardar Cambios
            </Button>
          </Form.Item>
        </Form>
        {userInfo.profileImage && (
          <div className="profile-image-preview">
            <h3>Imagen de Perfil:</h3>
            <img
              src={URL.createObjectURL(userInfo.profileImage)}
              alt="Perfil"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
          </div>
        )}

        <h2>Cambiar Contraseña</h2>
        <Form onSubmit={handlePasswordSubmit} layout="vertical">
          <Form.Item label="Contraseña Actual">
            <Input.Password
              name="currentPassword"
              value={passwordInfo.currentPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Item>
          <Form.Item label="Nueva Contraseña">
            <Input.Password
              name="newPassword"
              value={passwordInfo.newPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Item>
          <Form.Item label="Confirmar Nueva Contraseña">
            <Input.Password
              name="confirmPassword"
              value={passwordInfo.confirmPassword}
              onChange={handlePasswordChange}
              required
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cambiar Contraseña
            </Button>
          </Form.Item>
        </Form>
        </div>   
    </div>
  );
};

export default ConfigUsr;