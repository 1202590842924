import React , { useState } from 'react';
import './customers.css';
import NavBar from '../navBar/navBar.jsx';
import { useStore } from '../store/store.jsx'; // Importa el store de Zustand



function Customers() {
  const { customers, setCustomers } = useStore(); // Obtiene la lista de clientes y la función para actualizarla
  const [newCustomer, setNewCustomer] = useState({ name: '', email: '' }); // Estado para el nuevo cliente

  // Maneja el cambio en el formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });
  };

  // Maneja el envío del formulario para agregar un nuevo cliente
  const handleAddCustomer = (e) => {
    e.preventDefault();
    if (newCustomer.name && newCustomer.email) {
      const newCustomerData = {
        id: customers.length + 1, // Genera un ID simple
        name: newCustomer.name,
        email: newCustomer.email,
        createdAt: new Date().toISOString(),
      };
      setCustomers([...customers, newCustomerData]); // Actualiza la lista de clientes
      setNewCustomer({ name: '', email: '' }); // Resetea el formulario
    }
  };

  // Maneja la eliminación de un cliente
  const handleDeleteCustomer = (id) => {
    const updatedCustomers = customers.filter(customer => customer.id !== id);
    setCustomers(updatedCustomers); // Actualiza la lista de clientes
  };

  return (
    <div className='ResponsiveShiet'>
      <div className='Body_Content'>
        <div className='Tittle'>
          <h2>Customers</h2>
        </div>
        <div className='NavBar'>
          <NavBar />
        </div>
        <form onSubmit={handleAddCustomer} className="add-customer-form">
          <input
            type="text"
            name="name"
            placeholder="Customer Name"
            value={newCustomer.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Customer Email"
            value={newCustomer.email}
            onChange={handleChange}
            required
          />
          <button type="submit">Add Customer</button>
        </form>
        <div className='CustomerList'>
          {customers.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>{customer.name}</td>
                    <td>{customer.email}</td>
                    <td>{new Date(customer.createdAt).toLocaleDateString()}</td>
                    <td>
                      <button onClick={() => handleDeleteCustomer(customer.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No customers found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Customers;