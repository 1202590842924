import React, { useRef, useState } from 'react';
import axios from '../axios/axios.jsx'; // Asegúrate de que la ruta sea correcta
import './recoverpassword.css';

const RecoverPassword = () => {
  const emailRef = useRef();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/recover-password', { email });
      console.log(response.data);
      setSuccess(true);
      setErrMsg('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No existe respuesta desde el Servidor.');
      } else {
        setErrMsg('Error al enviar el enlace de recuperación.');
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="card bg-transparent border-light">
        <div className="card-body">
          {success ? (
            <section>
              <h1 className="text-success">¡Éxito!</h1>
              <p>A message has been send to your email.</p>
              <p><a href="/Login">Return to Login</a></p>
            </section>
          ) : (
            <section>
              <h1 className="text-light">Recovery Password</h1>
              {/* Mostrar el mensaje de error si existe */}
              <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email" className="text-light">Email:</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    ref={emailRef}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    aria-describedby="emailnote"
                  />
                </div>
                <button type="submit" className="btn btn-primary">Send recovery link</button>
              </form>
              <p className="mt-3 text-white font-weight-bold">
                ¿You have an account?<br />
                <span className="line">
                  <a href="/Login">Iniciar Sesión</a>
                </span>
              </p>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;