import React from 'react';
import Nav from '../../navBar/navBar.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nrml_user.css';

function nrml_user() {
  return (
    <div className='User Div'>
      <div className='grid-container'>
        <div className='NavBar'>
          <Nav /> 
        </div>
        <div className= "Tittle"> <p>Hola mundo Normal User</p></div>
      </div>
    </div>
  );
}


export default nrml_user