import { useRef, useState, useEffect } from "react";
import axios from '../axios/axios.jsx';
import './register.css';
import '../login/login.css';
import Button from 'react-bootstrap/Button';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const REGISTER_URL = '/register';

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [nick, setNick] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [business, setBusiness] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [pwd, setPwd] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validaciones
    if (!USER_REGEX.test(nick) || !PWD_REGEX.test(pwd) || !EMAIL_REGEX.test(email)) {
      setErrMsg("Invalid Entry");
      return;
    }
    if (pwd !== matchPwd) {
      setErrMsg("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(REGISTER_URL,
        JSON.stringify({ nick, firstName, lastName, email, phoneNumber, business, enterprise, pwd }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: false
        });
      setSuccess(true);
      // Limpiar campos
      setNick('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setBusiness('');
      setEnterprise('');
      setPwd('');
      setMatchPwd('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No existe respuesta desde el Servidor.');
      } else {
        setErrMsg('Registro Fallido.');
      }
      errRef.current.focus();
    }
  }

  return (
    <>
      <div className="container mt-5 ">
        <div className="card bg-transparent border-light">
          <div className="card-body">
            {success ? (
              <section>
                <h1 className="text-success">¡Éxito!</h1>
                <p>
                  <a href="/">Sign In</a>
                </p>
              </section>
            ) : (
              <section>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <h1 className="text-light ">Register</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="nick">
                      <p className="text-light inline-icon">Nick (Username):</p>
                    </label>
                    <input
                      type="text"
                      id="nick"
                      className="form-control"
                      ref={userRef}
                      onChange={(e) => setNick(e.target.value)}
                      value={nick}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="firstName">
                      <p className="text-light inline-icon">First Name:</p>
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastName">
                      <p className="text-light inline-icon">Last Name:</p>
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="form-control"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                    />
                  </div >
                  <div className="form-group">
                    <label htmlFor="email">
                      <p className="text-light inline-icon">Email:</p>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </div>
                  <div className="form-group">
                  <label htmlFor="phoneNumber">
                    <p className="text-light inline-icon">Phone Number:</p>
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    className="form-control"
                    onChange={(e) => {
                      // Solo permitir números
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setPhoneNumber(value);
                    }}
                    value={phoneNumber}
                    required
                    pattern="[0-9]*" // Asegura que solo se acepten números
                    inputMode="numeric" // Muestra el teclado numérico en dispositivos móviles
                  />
                </div>

                  <div className="form-group">
                    <label htmlFor="business">
                      <p className="text-light inline-icon">Business:</p>
                    </label>
                    <input
                      type="text"
                      id="business"
                      className="form-control"
                      onChange={(e) => setBusiness(e.target.value)}
                      value={business}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="enterprise">
                      <p className="text-light inline-icon">Enterprise:</p>
                    </label>
                    <input
                      type="text"
                      id="enterprise"
                      className="form-control"
                      onChange={(e) => setEnterprise(e.target.value)}
                      value={enterprise}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pwd">
                      <p className="text-light inline-icon">Password:</p>
                    </label>
                    <input
                      type="password"
                      id="pwd"
                      className="form-control"
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      required
                      pattern={PWD_REGEX}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="matchPwd">
                      <p className="text-light inline-icon">Confirm Password:</p>
                    </label>
                    <input
                      type="password"
                      id="matchPwd"
                      className="form-control"
                      onChange={(e) => setMatchPwd(e.target.value)}
                      value={matchPwd}
                      required
                    />
                  </div>

                  <Button type="submit" className="btn btn-primary">Register</Button>
                </form>
              </section>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;