import React, { useState } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  Input, 
  message, 
  Switch 
} from 'antd';
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  UserOutlined 
} from '@ant-design/icons';
import './userManagement.css';
import NavBar from '../navBar/navBar';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [form] = Form.useForm();

  // Open modal for adding/editing user
  const handleAddEdit = (user = null) => {
    setCurrentUser(user);
    setIsModalVisible(true);
    
    // Reset or populate form
    form.resetFields();
    if (user) {
      form.setFieldsValue(user);
    }
  };

  // Save user (add/edit)
  const handleSave = (values) => {
    try {
      if (currentUser) {
        // Edit existing user
        const updatedUsers = users.map(u => 
          u.id === currentUser.id ? { ...u, ...values } : u
        );
        setUsers(updatedUsers);
        message.success('User updated successfully');
      } else {
        // Add new user
        const newUser = {
          ...values,
          id: users.length + 1,
          isActive: true
        };
        setUsers([...users, newUser]);
        message.success('User added successfully');
      }
      
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to save user');
    }
  };

  // Delete user
  const handleDelete = (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      onOk: () => {
        const updatedUsers = users.filter(u => u.id !== userId);
        setUsers(updatedUsers);
        message.success('User deleted successfully');
      }
    });
  };

  // Toggle user active status
  const handleToggleStatus = (user) => {
    const updatedUsers = users.map(u => 
      u.id === user.id 
        ? { ...u, isActive: !u.isActive } 
        : u
    );
    setUsers(updatedUsers);
  };

  // Table columns configuration
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      key: 'isActive',
      render: (record) => (
        <Switch 
          className="user-status-switch"
          checked={record.isActive}
          onChange={() => handleToggleStatus(record)}
        />
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <div className="action-buttons">
          <Button 
            className="edit-btn"
            icon={<EditOutlined />} 
            onClick={() => handleAddEdit(record)}
            type="ghost"
          >
            Edit
          </Button>
          <Button 
            className="delete-btn"
            icon={<DeleteOutlined />} 
            onClick={() => handleDelete(record.id)}
            type="ghost"
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className='ResponsiveShiet'>
      <div className='Body_Content'>
        <div className='Tittle'>
          <h2>Customers</h2>
        </div>
        <div className='NavBar'>
          <NavBar />
    <div className="user-management-container">
      <div className="user-management-header row">
        <h1>User Management</h1>
        <Button 
          type="primary" 
          icon={<PlusOutlined />}
          onClick={() => handleAddEdit()}
        >
          Add New User
        </Button>
      </div>

      {users.length > 0 ? (
        <Table 
          className="user-management-table"
          columns={columns} 
          dataSource={users}
          rowKey="id"
          pagination={{ 
            pageSize: 10, 
            showSizeChanger: true 
          }}
        />
      ) : (
        <div className="user-management-empty-state">
          <UserOutlined className="user-management-empty-state-icon" />
          <p className="user-management-empty-state-text">
            No users found. Click "Add New User" to get started.
          </p>
        </div>
      )}

      <Modal
        title={currentUser ? 'Edit User' : 'Add New User'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        className="user-management-modal"
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
        >
          <Form.Item
            name="name"
            label="Full Name"
            rules={[{ required: true, message: 'Please input user name' }]}
          >
            <Input placeholder="Enter full name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {currentUser ? 'Update User' : 'Add User'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
    </div>
      </div>
    </div>
  );
};

export default UserManagement;