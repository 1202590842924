import React from 'react';
import './perfil.css'; 
import Nav from '../navBar/navBar.jsx';
import { useStore } from '../store/store.jsx'; // Importa el store para acceder a la información del usuario
import ConfigUsr from '../configusr/config_usr.jsx'; // Se importa el componente config usr para mostrar las configuraciones del usuario.

function Perfil() {
  const { currentUser  } = useStore(); // Obtén el usuario actual del store

  // Verifica si currentUser  es null
  if (!currentUser ) {
    return <div>No se ha encontrado información del usuario. Por favor, inicie sesión.</div>;
  }

  return (
    <div className='Responsive_Body'>
      <div className='Body_Content_perfil'>
        <div className='Tittle'>
          <h2>Perfil de Usuario</h2>
        </div>
        <div className='NavBar'>
          <Nav />
        </div>
        <div className='profile-container'>
          <img src={currentUser .profileImage} alt="Perfil" className="profile-avatar" />
          <h2 className="profile-name">{currentUser .username} {currentUser .lastname}</h2>
          <p className="profile-bio"><strong>Email:</strong> {currentUser .email}</p>
          <p className="profile-bio"><strong>Numero:</strong> {currentUser .phonenumber}</p>
          <p className="profile-bio"><strong>Tipo de Usuario:</strong> {currentUser .userType}</p>
          <p className="profile-bio"><strong>Negocio:</strong> {currentUser .bussiness}</p>
          <p className="profile-bio"><strong>Empresa:</strong> {currentUser .company}</p>

          <ConfigUsr />
        </div>
      </div>
    </div>
  );
}

export default Perfil;